import {Route, Redirect} from 'react-router-dom';
import React from 'react';
//import Cookie from 'js-cookie';

import { connect } from 'react-redux';


const GuestRoute = ({ component: Component, ...rest }) => {

    //const token = Cookie.get('token');

    return (
      <Route
        {...rest}
        render={ props =>
          !rest.loggedIn ? (
            //children
            <Component {...props} />
          ) 
          
          :
          
          (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
  }
  
  
  //export default GuestRoute;

   

const mapStateToProps = state =>{

  return{
      loggedIn: state.auth.loggedIn
  }
}


//export default AuthRoute;

export default connect(mapStateToProps)(GuestRoute);
