import React from 'react';

const Modal = props => {
    return (

        <>
            <Backdrop show={props.show} clicked={props.modalClosed} />
            <div className="Modal" style={{
                transform:props.show ? 'translateY(0)' : 'translateY(-100vh)',
                opacity : props.show ? 1 : 0
            }}>
                {props.children}
            </div>

        </>
    );
};


export default Modal;



export const ModalCenter = props => {
    return (

        <>
            <Backdrop show={props.show} clicked={props.modalClosed} />
            <div className={props.size+ " ModalCenter"} style={{
                transform:props.show ? 'translateY(0)' : 'translateY(-100vh)',
                opacity : props.show ? 1 : 0
            }}>
                {props.children}
            </div>

        </>
    );
};



export const ModalLoading = props => {
    return (

        <>
            <Backdrop show={props.show} clicked={props.modalClosed} />
            <div className="ModalLoading" style={{
                transform:props.show ? 'translateY(0)' : 'translateY(-100vh)',
                opacity : props.show ? 1 : 0
            }}>
                <div className="mt-48">
                    <img src={require('./../dist/images/logo-white.png')} width={100} className="m-auto" />
                    <div className='text-center text-white text-2xl mt-2'> Loading..... </div>
                </div>
                 {/* {props.children} */}


            </div>

        </>
    );
};







const Backdrop = (props) => (
    props.show ? <div className="Backdrop"></div> : null
)


