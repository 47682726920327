import React from 'react';
import ReactDOM from 'react-dom';
// import { BrowserRouter } from 'react-router-dom';
import './dist/css/tailwind.css';
import App from './App';
import reportWebVitals from './reportWebVitals';



import store from './store/index';
import { Provider } from 'react-redux';
import axios from 'axios';
import cookie from 'js-cookie';
import jwt from 'jsonwebtoken';
import { ServerLink } from './keys/Links';




const jwt_secret = '5fT2orzINhYsAFs7CTR0rhI8oZhytd3ErHQFmDup3LCWvGQFfSnWmsB0M30Gjj9F';
//const jwt_secret = 'hjc3gxxgh0zF2DLcFWPZ73GoXWAlwxys0UlBcRpDU26iCw2yZsTd7vrd5GBKx9x7'; //for the live jwt
let token = cookie.get("token");


//console.log(cookie.get("token"));

if(token){

  //cookie.remove("token");

  

}


/*
if (token) {


  //console.log("token wa")

  jwt.verify(token, jwt_secret, (err, decoded) => {

    if (err) {
      //console.log('remove token 1')
      cookie.remove("token");
      token = null;

    }
    else {
      if (ServerLink + 'api/register' !== decoded.iss && ServerLink + 'api/login' !== decoded.iss) {

        //console.log("Help");
        cookie.remove("token");
        token = null;

        //console.log(decoded.iss)
        //console.log(ServerLink+'/register')
      }
    }



    //console.log(decoded);
  }

  );
}

*/



const Render = () => {

  ReactDOM.render(

    <Provider store={store}>
      <React.StrictMode>
        <App />

      </React.StrictMode>
    </Provider>,
    document.getElementById('root')
  );
}

if (token) {

  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  axios.get(ServerLink + 'api/user').then(
    res => {
      store.dispatch({ type: "SET_LOGIN", payload: res.data.data });
      //console.log(res.data.data)
      Render();
    });
}

else {
  Render();
}






/*
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
*/




reportWebVitals();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

