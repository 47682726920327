import React from 'react';
import { HashRouter, BrowserRouter, Route, Switch } from 'react-router-dom';
import Landing from './pages/Landing';


import './dist/css/resident.css';
import './dist/css/resident-media.css';
import './dist/css/bootstrap.min.css';


//import Login from './authentication/Login';
//import Register from './authentication/Register';
//import Forgot from './authentication/Forgot';
//import Reset from './authentication/Reset';


import GuestRoute from './components/GuestRoute';
import AuthRoute from './components/AuthRoute';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import settingUp from './residence/SettingUp';
//import Residence from './pages/Residence/Residence';



/*
import Register from './authentication/Register';
import Confirm from './residence/Confirm';
import ShowResidence from './residence/ShowResidence';
import Dashboard from './pages/Dashboard';
import Wallet from './pages/Wallet';
import Transactions from './pages/Transactions';
import Notifications from './pages/Notifications';
import Profile from './profile/Profile';
import AllResidence from './pages/Residence/AllResidence';
*/


const loading2 = (
  <div className="pt-56 my-0 text-center py-64 h-screen">

    <img src={require('./dist/images/animation/sn2.gif')} width={150} className="m-auto" />

  </div>
);


//const About = React.lazy(()=> import('./pages/about'));

const ShowResidence = React.lazy(() => import('./residence/ShowResidence'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Wallet = React.lazy(() => import('./pages/Wallet'));
const Transactions = React.lazy(() => import('./pages/Transactions'));
const Notifications = React.lazy(() => import('./pages/Notifications'));
const Profile = React.lazy(() => import('./profile/Profile'));
const AllResidence = React.lazy(() => import('./pages/Residence/AllResidence'));
const Residence = React.lazy(() => import('./pages/Residence/Residence'));
const Register = React.lazy(() => import('./authentication/Register'));
const Login = React.lazy(() => import('./authentication/Login'));
const Confirm = React.lazy(() => import('./residence/Confirm'));

function App() {
  return (

    <>
      {/* <BrowserRouter> */}
      <HashRouter>
        <React.Suspense fallback={loading2}>
          <Switch>

            <GuestRoute path='/' exact component={Login} />
            <GuestRoute path='/login' component={Login} />
            <GuestRoute path='/register' component={Register} />

            <AuthRoute path='/confirm-residence' component={Confirm} />
            <AuthRoute path='/show-residence' component={ShowResidence} />
            <AuthRoute path='/dashboard' component={Dashboard} />
            <AuthRoute path='/wallet' component={Wallet} />
            <AuthRoute path='/residences' component={AllResidence} />
            <AuthRoute path='/residence/:id' component={Residence} />
            <AuthRoute path='/transactions' component={Transactions} />
            <AuthRoute path='/notifications' component={Notifications} />
            <AuthRoute path='/profile' component={Profile} />
            <AuthRoute path='/loading-setup' component={settingUp} />


          </Switch>
        </React.Suspense>
        <ToastContainer className="f-visby" />
      </HashRouter>
      {/* </BrowserRouter> */}
    </>


  );
}

export default App;
