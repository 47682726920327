import React, { Component } from 'react'
import { Container, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Error from '../inc/Error';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import cookie from 'js-cookie';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import postApi from '../api_request/Post';
import { ServerLink } from '../keys/Links';
import notifyError from '../inc/SnackbarError';
import logo from '../dist/images/logo.png';
import PinInput from 'react-pin-input';
import Modal, { ModalCenter, ModalLoading } from "../modals/Modal";
import FeatherIcon from 'feather-icons-react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, MarkerWithLabel, google } from "react-google-maps";

import searchIcon from '../dist/images/empty_results_animation.svg';
import markerIcon from '../dist/images/selocation2.png';

import './../dist/css/map_style.css';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

class settingUp extends Component {

    constructor(props, context) {
        super(props, context);

    }


    componentDidMount() {

        //window.location.replace('/dashboard');

        //this.checkUserStatus()
    }


    checkUserStatus() {

        if (this.props.userInfo.status == true) {
            window.location.replace('/#/dashboard');
        }
        else {
            window.location.reload();
        }

        console.log('Hello');
    }





    render() {

        return (
            <div className='bg-gray-200 h-screen authBgx'>

                <div className='container'>

                    <div className="lg:w-2/4 mx-auto pt-48">

                        <div className="bg-white shadow-md text-center w-full pb-4" style={{ minHeight: '200px', marginTop: '' }}>

                            <div className='pt-4 countCenter' style={{ margin: 'auto' }}>

                                <CountdownCircleTimer
                                    isPlaying
                                    duration={3}
                                    colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                                    colorsTime={[7, 5, 2, 0]}
                                    size={120}
                                    style={{ margin: 'auto' }}
                                    className='ffg'
                                    onComplete={() => this.checkUserStatus()}
                                >
                                        {({ remainingTime }) => remainingTime}
                                    
                                </CountdownCircleTimer>


                                <div className='pt-4'> Please wait your residence account is propagating...</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

}



//export default settingUp

const mapStateToProps = state => {

    return {
        loggedIn: state.auth.loggedIn,
        userInfo: state.auth.user

    }
}


const mapDispatchProps = dispatch => {

    return {
        loggout: () => dispatch({ type: 'SET_LOGOUT', })
    }
}





export default connect(mapStateToProps, '')(settingUp);


